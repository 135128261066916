.header__container {
    height: 100vh;
    max-width: var(--max-width);
    margin: auto;
    padding: 2rem 2rem 4rem 2rem;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content:center;
}
.hero__area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    transform: translateY(-25%);
}

.hero__title {
    /* font-family: "Bebas Neue"; */
    text-align: left;
    width: 100%;
    font-size: 13vw;
    line-height: 13vw;
    font-weight: 400;
}
.hero__title__second__line {
    text-align: right;
}

.hero__area .section__subheader {
    margin-top: 2rem;
    text-align: center;
}

@media (width > 768px) {

    .hero__area {
        width: 80%;
        transform: translateY(0%);
    }

    .hero__title {
        font-size: 10vw;
        line-height: 10vw;
    }

}

/* Regulating hero title text for large displays */
@media (width > 1920px) {
    .hero__title {
        font-size: 8vw;
        line-height: 8vw;
    }
}
@media (width > 2560px) {
    .hero__title {
        font-size: 3.7vw;
        line-height: 3.7vw;
    }
}







