.loader__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: var(--primary-color-dark);

    display: flex;
    align-items:center;
    justify-content: center;
}

.loader__container {
    transform: translateY(-200%);
}

.loader__item {
    display: inline-block;
    overflow: hidden;
    font-size: 8.7vw;

    margin-right: 10px;
}

@media (width > 768px) {

    .loader__container {
        transform: translateY(0%);
    }

    .loader__item {
        font-size: 3.7vw;
    }
}