nav {
    position: fixed;
    width: 100%;
    /* this will center a fixed element */
    left: 50%;
    transform: translateX(-50%);

    z-index: 3;

    /* Iverted effect on menu bar */
    mix-blend-mode: exclusion !important;
    filter: invert(1) !important;

    /* styling for the menu bar since 
    everything is inherited in links */
    font-size: 1rem;
    font-weight: 100;
    
}
.nav__header {
    width: 100%;
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;    
}
.nav__logo a {
    font-size: 1.5rem;
    font-weight: 400;
    color: inherit;
}
.nav__menu__btn {
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    cursor: pointer;
}
/* mobile viewport links */
.nav__links__wrapper {    
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100vh;
    padding: 2rem;
    background-color: var(--extra-light) !important;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    
    z-index: 2;
}
.nav__links__mobile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    gap: 1rem;
}
.nav__links__mobile .app__link {
    color: inherit;
    font-size: 10vw;
}
/* desktop viewport links */
.nav__links__desktop {
    display: none;
}

.nav__btns {
    display: none;
}

@media (width > 768px) {
    nav {
        padding: 2rem 2rem;
        max-width:var(--max-width);
        
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 2rem;
    }
    .nav__header {
        flex: 1;

        padding: 0;
        background-color: transparent;
    }
    .nav_logo a {
        font-size: 1.25rem;
    }
    .nav__menu__btn {
        display: none;
    }
    .nav__links__desktop {
        flex: 1;

        position: static;
        padding: 0;

        display: flex;
        flex-direction: row;
        background-color: transparent;
        
        gap: 0.5rem;
    }
    .nav__links__desktop .app__link {
        color: inherit;
    }
    .nav__btns {
        flex: 2;

        display: flex;
        justify-content: end;
    }
}