/* Helvetica Neue */
@font-face {
    font-family: 'Helvetica Neue Light';
    src: local('Helvetica Neue Light'), url(./assets/fonts/helvetica-neue-5/HelveticaNeueLight.otf) format('woff');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}
@font-face {
    font-family: 'Helvetica Neue Medium';
    src: local('Helvetica Neue Medium'), url(./assets/fonts/helvetica-neue-5/HelveticaNeueMedium.otf) format('woff');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}
@font-face {
    font-family: 'Helvetica Neue Black';
    src: local('Helvetica Neue Black'), url(./assets/fonts/helvetica-neue-5/HelveticaNeueBlack.otf) format('woff');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}
/* Nohemi */
@font-face {
    font-family: 'Nohemi Medium';
    src: local('Nohemi Medium'), url(./assets/fonts/Nohemi/Web-TT/Nohemi-Medium.woff) format('woff');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

:root {
    --primary-color: #f5f5f5;
    --primary-color-dark: #abb8c3;
    --text-dark: #1B1B1C;
    --text-light: #4D5153;
    --extra-light: #E5E5E5;
    --white: #ffffff;
    --max-width: 1920px;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.section__container {
    position: relative;
    z-index: 1;

    width: 100%;

    background-color: var(--primary-color);
}

.section__subheader {
    font-size: 1rem;
    font-weight: 400;
    color: var(--text-dark);

    margin-bottom: 0.5rem;
}

.section__header {
    font-size: 2.5rem;
    font-weight: 400;
    color: var(--text-dark);

    line-height: 3rem;
}

.section__description {
    font-size: 1rem;
    font-weight: 200;
    color: var(--text-light);
}

ul {
    list-style: none;
}

html,
body {
    scroll-behavior: smooth;
    overscroll-behavior: none;
}

body {
    font-family: "Nohemi Medium";
    font-size: 16px; /* default size 16px */
    color: var(--text-dark);
    background-color: var(--primary-color);
}

/* heading reset */
h1,h2,h3,h4,h5,h6 {
    font-weight: normal;
    font-style: normal;
}

/* hyperlink style */
a {
    text-decoration: none;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
}


.app__link {
    position: relative;
}
.app__link::before {
    content: "";
    width: 0%;
    height: 1px;
    background-color: var(--text-dark);
    position: absolute;
    bottom: -0.6rem;
    right: 0;
    transition: right 0.5s, width 0.5s, left 0.5s;
    transition-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
}
.app__link:hover::before {
    width: 100%;
    left: 0;
}

.app__link__inverted {
    position: relative;
}
.app__link__inverted::before {
    content: "";
    width: 100%;
    height: 1px;
    background-color: var(--text-dark);
    position: absolute;
    bottom: -0.60rem;
    right: 0;
    transition: right 0.5s, width 0.5s, left 0.5s;
    transition-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
}
.app__link__inverted:hover::before {
    width: 0%;
    left: 0;
}

/* transition overlay */
.slide-in {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: var(--primary-color-dark);
    z-index: 4;
    /* needed for framer motion effect */
    transform-origin: bottom; 

}

.slide-out {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: var(--primary-color-dark);
    z-index: 4;
    /* needed for framer motion effect */
    transform-origin: top; 

}

